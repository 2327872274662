import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import { InfoService } from 'src/app/_services/info.service';
import { UserService } from 'src/app/_services/user.service';
import { AccessRequestComponent } from '../access-request/access-request.component';

@Component({
  selector: 'dis-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {
  numSAP: number | undefined;
  caralUploadedAt: string | undefined;
  fidUploadedAt: string | undefined;
  authenticated: boolean = false;
  processing: boolean = false;
  pendingAccessRequestCount: number = 0;
  
  get needsMFA(): boolean | undefined {
    return this._userService.currentUser?.mfa !== true;
  }

  get profile(): string | undefined {
    return this._userService.currentUser?.profile;
  }

  get accessRequested(): boolean {
    return this._userService.currentUser?.accessRequestStatus != undefined;
  }
  get accessRequestStatus(): number | undefined {
    return this._userService.currentUser?.accessRequestStatus;
  }
  get accessRequestReason(): string | undefined {
    return this._userService.currentUser?.accessRequestReason;
  }

  constructor(private _activatedRoute: ActivatedRoute, private _router: Router, private _infoService: InfoService, private _userService: UserService, 
      private _adminService: AdminService, private _dialog: MatDialog) {

  }

  ngOnInit() {
    this._activatedRoute.paramMap.subscribe(params => {
      const token = params.get('token');
      this._userService.authenticate(token).subscribe(
        r => this.setAuth(r)
      );
    }); 
  }

  private _loadInfos() {    
    this._infoService.getInfo('CARAL').subscribe(
      res=> this.caralUploadedAt = res
    );
    this._infoService.getInfo('FID').subscribe(
      res=> this.fidUploadedAt = res
    );
    if (this._userService.currentUser?.profile != 'USR') {
      this._userService.countAccessRequests().subscribe(
        res => this.pendingAccessRequestCount = res
      );
    }
  }

  setAuth(auth: boolean) {        
    this.authenticated = auth;
    if (auth) {
      this._router.navigate(['']);
      if (!this.needsMFA) {
        this._loadInfos();
      }
    }
  }

  logout() {    
    this.authenticated = false;
    this._userService.currentUser = undefined;
    localStorage.removeItem('access_token');
  }

  get noSAPNumber(): boolean {
    return this.profile == 'USR' && this._userService.currentUser?.sapNumbers.length == 0;
  }

  download(code: string) {
    this.processing = true;    
    this._adminService.download(code).subscribe(
      (res: Response) => {
        const data: any = res.body;

        const element = document.createElement('a');
        element.href = URL.createObjectURL(data);
        
        const content = res.headers.get('Content-Disposition');        
        const matches = content?.match(/filename=\"?([^;\"]+)\"?/);
        
        if (matches && matches.length > 0) {
          const filename = matches[1];
          console.log(filename);
          element.download = filename;
        }
        
        document.body.appendChild(element);
        element.click();       
        this.processing = false;
    }); 
  }

  requestAccess() {
    const dialogRef = this._dialog.open(AccessRequestComponent, {      
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          // this.loadUsers();
        }
      }
    )
  }

}
