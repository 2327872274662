<mat-dialog-content>
    <h3 mat-dialog-title>
        {{ title }}
    </h3>
    
    <p [outerHTML]="message"></p>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="confirmation">
    <button mat-button (click)="onDismiss()">Non</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Oui</button>
</mat-dialog-actions>

<mat-dialog-actions align="end" *ngIf="!confirmation">
    <button mat-button (click)="onDismiss()">OK</button>
</mat-dialog-actions>
