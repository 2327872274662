import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dis-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.less']
})
export class ConfirmDialogComponent implements OnInit {

  title: string = "";
  message: string = "";
  confirmation: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { title: string, message: string, confirmation: boolean },
    private _dialogRef: MatDialogRef<ConfirmDialogComponent>) { 
    this.title = data.title;
    this.message = data.message;
    this.confirmation = data.confirmation;
  }

  ngOnInit(): void {
  }

  onConfirm() {
    this._dialogRef.close(true);
  }
  onDismiss() {
    this._dialogRef.close(false);
  }
}