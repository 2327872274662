import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'dis-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.less']
})
export class MfaComponent {
  mfac: string | undefined;
  error: string | undefined;
  canResend: boolean = true;
  processing: boolean = false;
  

  get mfacValid(): boolean {
    if (!this.mfac) {
      return false;
    } else {
      return this.mfac.match(/^[0-9]{6}$/) != null;
    }
  }

  constructor(private _userService: UserService, private _snackBar: MatSnackBar) {

  }

  checkCode() {
    if (!this.mfac) {
      return;
    }
    this.processing = true;
    this._userService.checkMFA(this.mfac).subscribe(
      {
        next: (res) => {
          if (res) {
            if (this._userService.currentUser) {
              this._userService.currentUser.mfa = true;
            }
          } else {            
            this._snackBar.open('Code de vérification incorrect', 'OK', {duration: 5000 });
          }
        },
        error: (err) => {            
          this._snackBar.open('Une erreur est survenue lors de la vérification du code.', 'OK', {duration: 5000 });
          this.processing = false;
        },
        complete: () => {
          this.processing = false;
        }
      }
    )
  }

  resendCode() {
    this.processing = true;
    this.canResend = false;
    this._userService.resendMFAC().subscribe(
      {
        next: (res) => {
          if (res == 'LIMIT_EXCEEDED') {
            this._snackBar.open('Vous avez demandé trop d\'envoi de ce code !', 'OK', {duration: 5000 });
          } else if (!res.match(/^20/)) {
            this.canResend = true;            
            this._snackBar.open('Une erreur est survenue lors de l\'envoi de l\'email.', 'OK', {duration: 5000 });
          } else {
            this._snackBar.open('L\'email a bien été envoyé. Merci de patienter, il peut mettre quelques minutes à arriver dans votre boîte mail...', 'OK', {duration: 10000 });
          }
        },
        error: (err) => {            
          this._snackBar.open('Une erreur est survenue lors de l\'envoi de l\'email.', 'OK', {duration: 5000 });
          this.canResend = true;
          this.processing = false;
        },
        complete: () => {
          this.processing = false;
        }
      }
    )
  }

}
