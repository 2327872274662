import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './app.component';
import { MenuComponent } from './_components/menu/menu.component';
import { LoginComponent } from './_components/login/login.component';
import { ConfirmDialogComponent } from './_components/confirm-dialog/confirm-dialog.component';

import { AuthGuardService } from './_services/auth-guard.service';
import { AccessRequestComponent } from './_components/access-request/access-request.component';
import { ClaimComponent } from './_components/claim/claim.component';
import { MfaComponent } from './_components/mfa/mfa.component';

const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
  },
  {
    path: 'sso/:token',
    component: MenuComponent,
  },
  // {
  //   path: 'claim',
  //   component: ClaimComponent,
  // },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'caral',
    loadChildren: () => import('./caral/caral.module').then(m => m.CaralModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: ''    
  }
];

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LoginComponent,
    ConfirmDialogComponent,
    AccessRequestComponent,
    ClaimComponent,
    MfaComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,    
    RouterModule.forRoot(routes),
    FormsModule,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,    
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatToolbarModule,
    MatSelectModule,
    MatRadioModule,
    MatSnackBarModule,
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthGuardService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
