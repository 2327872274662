import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {
  protected urlBase: string;

  constructor(protected httpClient: HttpClient) { 
    const url = environment.urlBase;
    let protocol = 'https';//location.protocol;
    if (!protocol.endsWith(":")) {
        protocol += ":";
    }
    this.urlBase = `${protocol}//${url}`;
  }
}
