<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>Vérification</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>Merci d'entrer le code à 6 chiffres<br />que nous vous avons envoyé par e-mail :</p>
        <p>
            <mat-form-field appearance="fill">            
                <input matInput type="text" [(ngModel)]="mfac" (keyup.enter)="checkCode()" />
            </mat-form-field>
        </p>
        <p class="no-code">
            Vous n'avez pas reçu d'email ?<br />
            Le traitement peut prendre quelques minutes...<br />
            Vérifiez cependant dans le dossier "Indésirables",<br />
            ou <a href="javascript:;" (click)="resendCode()" [class.disabled]="!canResend">Demandez un nouvel envoi</a>
        </p>
        <div class="error" *ngIf="error">
            {{ error }}
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button (click)="checkCode()" [disabled]="!mfacValid">Vérifier</button>
    </mat-card-actions>
</mat-card>
<div class="spinner-container" *ngIf="processing">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>