import { Component, OnInit } from '@angular/core';
import { Claim, ClaimService, KeyValuePair } from 'src/app/_services/claim.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'dis-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.less']
})
export class ClaimComponent implements OnInit {
  processing: boolean = false;
  error: string | undefined = undefined;
  private _claim: Claim;

  claimReasons: KeyValuePair[] = [];

  returnReasons: KeyValuePair[] = [];

  get email(): string | undefined {
    return this._claim.email;
  }
  set email(value: string | undefined) {
    this._claim.email = value;
  }

  get name(): string | undefined {
    return this._claim.name;
  }
  set name(value: string | undefined) {
    this._claim.name = value;
  }

  get phoneNumber(): string | undefined {
    return this._claim.phoneNumber
  };
  set phoneNumber(value: string | undefined) {
    this._claim.phoneNumber = value;
  };

  get clientNumber(): string | undefined {
    return this._claim.clientNumber;
  };
  set clientNumber(value: string | undefined) {
    this._claim.clientNumber = value;
  }

  get arNumber(): string | undefined {
    return this._claim.arNumber;
  };
  set arNumber(value: string | undefined) {
    this._claim.arNumber = value;
  };

  get reason(): string | undefined {
    return this._claim.reasonCode;
  };
  set reason(value: string | undefined) {
    this._claim.reasonCode = value;
  };

  get reasonDetail(): string | undefined {
    return this._claim.reason;
  };
  set reasonDetail(value: string | undefined) {
    this._claim.reason = value;
  };

  get deliveryRefused(): boolean | undefined {
    return this._claim.deliveryRefused;
  };
  set deliveryRefused(value: boolean | undefined) {
    this._claim.deliveryRefused = value;
  };


  get returnRequested() : boolean | undefined {
    return this._claim.returnRequested;
  };
  set returnRequested(value : boolean | undefined) {
    this._claim.returnRequested = value;
  };

  get returnReason(): string | undefined {
    return this._claim.returnReasonCode;
  };
  set returnReason(value: string | undefined) {
    this._claim.returnReasonCode = value;
  };

  get references(): string | undefined {
    return this._claim.references;
  };
  set references(value: string | undefined) {
    this._claim.references = value;
  };

  get comments(): string | undefined {
    return this._claim.comments;
  };
  set comments(value: string | undefined) {
    this._claim.comments = value;
  };

  constructor(private _userService: UserService, private _claimService: ClaimService) {
    this._claim = new Claim();
    this._claim.email = this._userService.currentUser?.email;
  }

  ngOnInit() {
    this._claimService.getClaimReasons().subscribe(
      res => this.claimReasons = res
    );

    this._claimService.getReturnReasons().subscribe(
      res => this.returnReasons = res
    );
  }


  save() {

  }

}
