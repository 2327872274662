<mat-toolbar color="primary">
    <button mat-icon-button routerLink="/"><mat-icon>home</mat-icon></button>
    <span>Portail Distributeurs</span>
    <span class="toolbar-spacer"></span>
    <img class="height-80" alt="BDR Thermea France" src="/assets/logos/bdrfr-w.png" />
</mat-toolbar>
<div class="content fixed-width">
    <form>
        <h2>Formulaire de réclamation et de demande de reprise</h2>
        <div class="mandatory">Toutes les données sont obligatoires</div>
        <div class="form-row">
            <mat-form-field appearance="fill">
                <mat-label>Nom du client (raison sociale)</mat-label>
                <input matInput type="text" name="name" [(ngModel)]="name" />
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field appearance="fill">
                <mat-label>Adresse mail de contact</mat-label>
                <input matInput type="text" name="email" [(ngModel)]="email" />
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field appearance="fill">
                <mat-label>Numéro de téléphone où nous pouvons vous joindre</mat-label>
                <input matInput type="text" name="phoneNumber" [(ngModel)]="phoneNumber" />
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field appearance="fill">
                <mat-label>Votre numéro de compte BDR</mat-label>
                <input matInput type="text" name="email" [(ngModel)]="email" />
                <mat-hint>Commence par 100XXXXX</mat-hint>
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field appearance="fill">
                <mat-label>Numéro de confirmation de commande ou référence de commande</mat-label>
                <input matInput type="text" name="arNumber" [(ngModel)]="arNumber" />
                <mat-hint>Confirmation de commande BDR Thermea (6 chiffres) ou votre référence de commande</mat-hint>
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field appearance="fill">
                <mat-label>Motif de votre réclamation</mat-label>
                <mat-select [(ngModel)]="reason" name="reason">
                    <mat-option *ngFor="let item of claimReasons" [value]="item.key">{{ item.value }}</mat-option>
                </mat-select>                
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="reason == 'Autre'">
                <mat-label>Autre : Précisez le motif</mat-label>
                <input matInput type="text" name="reasonDetail" [(ngModel)]="reasonDetail" />
            </mat-form-field>
        </div>
        <div class="form-row">
            La livraison a-t-elle été refusée ?
            <mat-radio-group>
                <mat-radio-button value="1" [checked]="deliveryRefused === true" (change)="deliveryRefused = true">Oui</mat-radio-button>
                <mat-radio-button value="0" [checked]="deliveryRefused === false" (change)="deliveryRefused = false">Non</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="form-row">
            Demandez-vous une reprise de matériel ?
            <mat-radio-group>
                <mat-radio-button value="1" [checked]="returnRequested === true" (change)="returnRequested = true">Oui</mat-radio-button>
                <mat-radio-button value="0" [checked]="returnRequested === false" (change)="returnRequested = false">Non</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="form-row" *ngIf="returnRequested">
            <mat-form-field appearance="fill">
                <mat-label>Motif de la reprise</mat-label>
                <mat-select [(ngModel)]="returnReason" name="returnReason">
                    <mat-option *ngFor="let item of returnReasons" [value]="item.key">{{ item.value }}</mat-option>
                </mat-select>                
            </mat-form-field>
        </div>
        <p *ngIf="error" class="error">
            {{ error }}
        </p>
        <div class="actions">
            <button mat-button mat-dialog-close>Annuler</button>
            <button mat-raised-button (click)="save()" color="primary">Envoyer</button>
        </div>
    </form>
    <div class="spinner-container" *ngIf="processing">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
</div>