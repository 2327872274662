import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimService extends ApiService {

  getClaimReasons(): Observable<KeyValuePair[]> {
    return this.httpClient.get<KeyValuePair[]>(`${this.urlBase}/claim/reasons`);
  }
  getReturnReasons(): Observable<KeyValuePair[]> {
    return this.httpClient.get<KeyValuePair[]>(`${this.urlBase}/return/reasons`);
  }

  sendClaim(claim: Claim): Observable<Claim> {
    return this.httpClient.post<Claim>(`${this.urlBase}/claim`, claim);
  }

};

export class KeyValuePair {
  key: string | undefined;
  value:  string | undefined;
};

export class Claim {
  id: number | undefined;
  date: Date | undefined;
  name: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  clientNumber: string | undefined;
  arNumber: string | undefined;
  reasonCode: string | undefined;
  reason: string | undefined;
  deliveryRefused: boolean | undefined;
  returnRequested : boolean | undefined;  
  returnReasonCode: string | undefined;
  returnReason: string | undefined;
  references: string | undefined;
  comments: string | undefined;
};