import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoService } from 'src/app/_services/info.service';
import { AccessRequest, UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'dis-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./access-request.component.less']
})
export class AccessRequestComponent {
  request: AccessRequest;
  error: string | undefined = undefined;
  processing: boolean = false;
  siretError: string | undefined = undefined;

  get siretOK(): boolean {
    return /^[0-9]{14}/.test(this.request.siret ?? '');
  }

  get email(): string | undefined {
    return this.request.user?.email;
  }

  get siret(): string | undefined {
    return this.request.siret;
  }
  set siret(value: string | undefined) {
    this.request.siret = value;
  }

  get company(): string | undefined {
    return this.request.company;
  }
  set company(value: string | undefined) {
    this.request.company = value;
  }

  get zip(): string | undefined {
    return this.request.zip;
  }
  set zip(value: string | undefined) {
    this.request.zip = value;
  }

  get city(): string | undefined {
    return this.request.city;
  }
  set city(value: string | undefined) {
    this.request.city = value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { id: number },
    private _userService: UserService,
    private _infoService: InfoService,
    private _dialogRef: MatDialogRef<AccessRequestComponent>,
    private _dialog: MatDialog
  ) { 
    this.request = new AccessRequest();
    this.request.user = this._userService.currentUser;
  }

  searchSiret() {
    this.siretError = "";
    if (this.siret && this.siretOK) {
      this.processing = true;
      this._infoService.getCompanyFromSIRET(this.siret).subscribe(
        {
          next: (data) => {
            if (data) {  
              if (data.error) {
                this.siretError = data.error;
              } else {
                this.company = data.name;
                this.zip = data.zip;
                this.city = data.city;
              }
            }
          },
          error: () => {
            this.siretError = "Une erreur est survenue lors de la recherche des informations";
            this.processing = false;
          },
          complete: () => {
            this.processing = false;            
          }
        }
      )
    }
  }


  save() {
    this.error = this.request.check();
    if (!this.error) {
      this.processing = true;
      this._userService.requestAccess(this.request).subscribe(
        {
          next: (request) => {
            if (this.request) {  
              if (this._userService.currentUser) {
                this._userService.currentUser.accessRequestStatus = request?.status;
              }
              this._dialogRef.close(true);
            }
          },
          error: () => {
            this.processing = false;
          },
          complete: () => {
            this.processing = false;            
          }
        }
      );    
    }
  }
}
