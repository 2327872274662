import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends ApiService {

  upload(code: string, file: File): Observable<DocUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<DocUploadResponse>(`${this.urlBase}/doc/${code}`, formData);
  }

  download(code: string): Observable<any> {
    return this.httpClient.get(
      `${this.urlBase}/doc/${code}`, 
        { responseType: 'blob', observe: 'response'}
    );
  }
  
};

export class DocUploadResponse {
  status: string | undefined;
  ts: string | undefined;
};