<mat-card class="login-card">
  <mat-card-header>
    <mat-card-title>Accédez au portail</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p class="info">
      Utilisez vos identifiants de connexion au site professionnel Chappée, 
      De Dietrich ou Oertli pour vous identifier sur ce portail.<br />
      <strong>ATTENTION</strong> : seules les adresses email professionnelles sont autorisées (hors, Orange, Gmail, Free, etc.)
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Votre e-mail</mat-label>
        <input matInput type="text" [(ngModel)]="email" />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Mot de passe</mat-label>
        <input matInput type="password" [(ngModel)]="pwd" (keyup.enter)="login()" />
      </mat-form-field>
    </p>
    <p>
      <mat-checkbox [(ngModel)]="rememberMe">Se souvenir de moi</mat-checkbox>
    </p>
    <div class="error" *ngIf="error">
      {{ error }}
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="login()">Connexion</button>
  </mat-card-actions>
</mat-card>

<mat-card class="create-account-card">
  <mat-card-header>
    <mat-card-title>Créer un compte</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      Vous n'avez pas encore de compte professionnel ?<br />
      Cliquez sur l'un des logos ci-dessous pour en créer un :      
    </p>
    <div class="logos">
      <div><a target="_blank" href="https://www.chappee.com/user/register"><img src="/assets/logos/ch.png" alt="Chappée" /></a></div>
      <div><a target="_blank" href="https://pro.dedietrich-thermique.fr/professional/subscription"><img src="/assets/logos/dd.png" alt="De Dietrich" /></a></div>
      <div><a target="_blank" href="https://pro.oertli.fr/professional_oertli/subscription"><img src="/assets/logos/oe.png" alt="Oertli" /></a></div>
    </div>
    <p class="info">
      Après validation de votre compte par nos services, un délai de 2h environ sera nécessaire pour que votre accès au portail distributeurs soit opérationnel.
    </p>
  </mat-card-content>
</mat-card>