<div class="main">
    <div class="left primary-bg">
        <div class="content">
            <img alt="logo" src="/assets/logos/bdrfr-w.png" />
            <h1>Bienvenue sur le portail<br />Distributeurs</h1>
        </div>
        <div class="logout" *ngIf="authenticated">
            <button mat-icon-button (click)="logout()" matTooltip="Se déconnecter"><mat-icon>logout</mat-icon></button>
        </div>
    </div>
    <div class="right">        
        <ng-container *ngIf="!authenticated">
            <dis-login (onAuthenticated)="setAuth($event)"></dis-login>
        </ng-container>
        <dis-mfa *ngIf="authenticated && needsMFA"></dis-mfa>
        <ng-container *ngIf="authenticated && !needsMFA">
            <mat-card class="menu-item">
                <mat-card-header>
                    <mat-card-title>Carnet de commandes</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p *ngIf="profile == 'USR' && !noSAPNumber">
                        <br />
                        Accédez à votre carnet de commandes
                    </p>    
                    <p *ngIf="profile == 'ADM' || profile == 'GES'">
                        <mat-form-field appearance="fill" >
                            <mat-label>Numéro SAP</mat-label>
                            <input matInput type="string" [(ngModel)]="numSAP" placeholder="Donneur d'ordre ou recept." />
                        </mat-form-field>       
                    </p>
                    <p *ngIf="noSAPNumber" class="no-access">
                        Votre carnet de commande n'a pas encore été intégré.<br />
                        <span class="accessRequested" *ngIf="accessRequestStatus == 0">Demande en cours...</span>
                        <span class="accessRequested" *ngIf="accessRequestStatus == 2">Demande refusée,<br />Contactez notre service ADV.</span>
                    </p>
                    <p>
                        Dernière mise à jour : <b>{{ caralUploadedAt ?? 'Jamais' }}</b>
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="requestAccess()" *ngIf="(profile == 'USR' && noSAPNumber && !accessRequested); else caralAccessButton">Demander l'accès</button>
                    <ng-template #caralAccessButton>
                        <button mat-button [routerLink]="numSAP ? ['caral', numSAP] : ['caral']" [disabled]="(!numSAP && profile!= 'USR') || noSAPNumber" >Accéder</button>
                    </ng-template>
                </mat-card-actions>            
            </mat-card>

            <mat-card class="menu-item" *ngIf="fidUploadedAt">
                <mat-card-header>
                    <mat-card-title>Infos délais</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p>
                        <br />
                        Téléchargez la fiche Excel<br />'Info délais'
                    </p>                        
                    <p>
                        Dernière mise à jour : <b>{{ fidUploadedAt }}</b>
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="download('FID')">Télécharger</button>
                </mat-card-actions>            
            </mat-card>

            <mat-card class="menu-item">
                <mat-card-header>
                    <mat-card-title>Voir aussi...</mat-card-title>
                </mat-card-header>
                <mat-card-content>                    
                    <ul>
                        <li><a href="https://suivi.dedietrich-thermique.fr" target="_blank">Suivi de commandes</a></li>
                        <li><a href="https://disponibilite.dedietrich-chappee.fr" target="_blank">Disponibilité des produits</a></li>
                    </ul>
                </mat-card-content>
                <mat-card-actions>
                    
                </mat-card-actions>            
            </mat-card>

            <mat-card class="menu-item">
                <mat-card-header>
                    <mat-card-title>Réclamation et reprise</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p>
                        <br />
                        Accédez au formulaire de réclamation et de demande de reprise de marchandises
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button [routerLink]="['claim']">Accéder</button>
                </mat-card-actions>            
            </mat-card>

            <mat-card class="menu-item" *ngIf="profile == 'ADM' || profile == 'GES'">
                <mat-card-header>
                    <mat-card-title>Importation du CARAL</mat-card-title>
                </mat-card-header>
                <mat-card-content>                    
                    <p>
                        Mettez à jour le carnet de commande sur le portail, en important un fichier CARAL au format CSV
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button [routerLink]="['caral/import']">Accéder</button>
                </mat-card-actions>            
            </mat-card>

            <mat-card class="menu-item" *ngIf="profile == 'ADM' || profile == 'GES'">
                <mat-card-header>
                    <mat-card-title>Téléchargements</mat-card-title>
                </mat-card-header>
                <mat-card-content>                    
                    <p>
                        Gérez les téléchargements :
                    </p>
                    <p>
                        - Fiche Excel "Info délais"                        
                    </p>                    
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button [routerLink]="['admin/upload']">Accéder</button>
                </mat-card-actions>            
            </mat-card>

            <mat-card class="menu-item" *ngIf="profile == 'ADM' || profile == 'GES'">
                <mat-card-header [matTooltip]="pendingAccessRequestCount ? 'Nouvelle(s) demande(s) d\'accès en attente' : ''">
                    <mat-card-title [matBadge]="pendingAccessRequestCount" [matBadgeHidden]="pendingAccessRequestCount == 0">Gestion des utilisateurs</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p>
                        <br />
                        Accédez à la gestion des utilisateurs<br />
                        et demandes d'accès
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button [routerLink]="['admin/users']">Accéder</button>
                </mat-card-actions>            
            </mat-card>
        </ng-container>
    </div>
</div>
<div class="spinner-container" *ngIf="processing">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>