import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserService, User, LoginResponse } from 'src/app/_services/user.service';

@Component({
  selector: 'dis-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  @Output() onAuthenticated: EventEmitter<boolean> = new EventEmitter<boolean>();


  email: string = '';
  pwd: string = '';
  rememberMe: boolean = false;
  error: string = '';

  ngOnInit() {
    if (this._cookieService.check('email')) {
      this.email = this._cookieService.get('email');
      this.rememberMe = true;       
    }
  }

  constructor(private _userService: UserService, private _cookieService: CookieService) {

  }

  login() {
    this.error = '';
    if (!this.email) {
      this.error = 'Veuillez renseigner votre email';      
    } else if (!this.pwd) {
      this.error = 'Veuillez entrer votre mot de passe';
    }

    if (this.email && this.pwd) {
      this._userService.login(this.email, this.pwd).subscribe(        
        res => {
          switch (res.status) {
            case 'NOT_FOUND':
              this.error = 'Utilisateur non trouvé';
              break;
            case 'NOT_PREMIUM':
              this.error = 'Votre accès au portail distributeurs n\'a pas encore été défini.';
              break;
            case 'BAD_DOMAIN':
              this.error = 'L\'accès au portail distributeurs n\'est possible qu\'avec une adresse email professionnelle.';
              break;
            case 'OK':
              if (this.rememberMe) {
                let expire = new Date();
                expire.setDate(expire.getDate() + 15);
                this._cookieService.set('email', this.email, expire);
              } else if (this._cookieService.check('email')) {
                this._cookieService.delete('email');
              }
              if (res.user && res.token) {
                localStorage.setItem('access_token', res.token);
                this.onAuthenticated.next(true);
              }
              break
            default: 
              this.error = 'Une erreur est survenue';
              break;
          }
        }
      );      
    }
  }

}
