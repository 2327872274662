<form>
    <mat-dialog-content class="form">
        <h2>Demande d'accès</h2>
        <div class="mandatory">Toutes les données sont obligatoires</div>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>E-mail</mat-label>
                <input matInput type="text" name="email" [value]="email" [disabled]="true" />                
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill">
                <mat-label>SIRET</mat-label>
                <input matInput type="text" name="siret" [(ngModel)]="siret" placeholder="N° SIRET (14 chiffres)" maxlength="14" />
                <button mat-icon-button matSuffix (click)="searchSiret()" [disabled]="!siretOK" matTooltip="Pré-remplir les données de l'établissement à partir du SIRET">
                    <mat-icon>search</mat-icon>
                  </button>
            </mat-form-field>
            <span class="error" *ngIf="siretError">{{ siretError }}</span>
            <mat-form-field appearance="fill">
                <mat-label>Société</mat-label>
                <input matInput type="text" name="company" [(ngModel)]="company" />
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Code postal</mat-label>
                <input matInput type="text" name="zip" [(ngModel)]="zip" maxlength="5" />
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Ville</mat-label>
                <input matInput type="text" name="city" [(ngModel)]="city" />
            </mat-form-field>
        </p>      
        <p *ngIf="error" class="error">
            {{ error }}
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Annuler</button>
        <button mat-raised-button (click)="save()" color="primary">Envoyer</button>
    </mat-dialog-actions>
</form>
<div class="spinner-container" *ngIf="processing">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>