import { Injectable } from '@angular/core';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  public currentUser: User | undefined;

  login(email: string, pwd: string): Observable<LoginResponse> {
    const body = {
      email: email,
      pwd: pwd
    };
    return this.httpClient.post<LoginResponse>(`${this.urlBase}/login`, body).pipe(
      tap( res => {
        if (res && res.status == 'OK') {
          this.currentUser = res.user;
        }
      })
    );
  }

  checkToken(token: string, sso: boolean = false): Observable<LoginResponse> {
    if (sso) {
      return this.httpClient.get<LoginResponse>(`${this.urlBase}/sso/${token}`);
    } else {
      const body = {
        token: token
      };
      return this.httpClient.post<LoginResponse>(`${this.urlBase}/login`, body);
    }
  }

  authenticate(ssoToken: string | null = null): Observable<boolean> {
    if (this.currentUser) {
      return of(true);
    } else {
      const token = ssoToken ? ssoToken : localStorage.getItem('access_token');
      if (token) {
        return this.checkToken(token, ssoToken ? true : false).pipe(
          switchMap(res => {
            if (res && res.status == 'OK') {
              this.currentUser = res.user;
              if (res.token) {
                localStorage.setItem('access_token', res.token);
              }
              return of(true);
            } else {
              return of(false);
            }
          })
        );
      } else {
        return of(false);
      }
    }
  }

  getUsers(profile: string, param: string = ''): Observable<User[]> {
    if (param || profile) {
      return this.httpClient.get<User[]>(`${this.urlBase}/user?p=${profile}&x=${encodeURI(param)}`);
    } else {
      return this.httpClient.get<User[]>(`${this.urlBase}/user`);
    }    
  }

  getUser(id: number): Observable<User> {
    return this.httpClient.get<User>(`${this.urlBase}/user/${id}`);
  }

  findEzUsers(filter: string): Observable<EzUser[]> {
    if (filter) {
      return this.httpClient.get<EzUser[]>(`${this.urlBase}/ez/user/${filter}`);
    } else {
      return of([]);
    }
  }

  addOrUpdateUser(user: User): Observable<User> {
    const body = {
      email: user.email,
      profile: user.profile,
      sapNumbers: user.sapNumbers,
      pwd: user.pwd
    };
    if (user.id) {
      return this.httpClient.post<User>(`${this.urlBase}/user/${user.id}`, body);
    } else {
      return this.httpClient.post<User>(`${this.urlBase}/user`, body);
    }
  }

  deleteUser(id: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.urlBase}/user/${id}`);
  }

  requestAccess(request: AccessRequest): Observable<AccessRequest | null> {
    if (request.user && request.user.id) {
      return this.httpClient.post<AccessRequest>(`${this.urlBase}/user/${request.user.id}/ar`, request);
    } else {
      return of(null);
    }
  }

  countAccessRequests(): Observable<number> {
    return this.httpClient.get<number>(`${this.urlBase}/ar/count`);
  }

  getAccessRequests(): Observable<AccessRequest[]> {
    return this.httpClient.get<AccessRequest[]>(`${this.urlBase}/ar`);
  }

  getAccessRequest(id: number): Observable<AccessRequest> {
    return this.httpClient.get<AccessRequest>(`${this.urlBase}/ar/${id}`);
  }

  closeAccessRequest(id: number | undefined, status: number, reason: string | undefined = undefined): Observable<AccessRequest | null> {
    if (id) {
      const body = {
        status: status,
        reason: reason
      };
      return this.httpClient.post<AccessRequest>(`${this.urlBase}/ar/${id}`, body);
    } else {
      return of (null);
    }
  }

  checkMFA(code: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.urlBase}/mfa/${code}`);
  }
  resendMFAC(): Observable<string> {
    return this.httpClient.get<string>(`${this.urlBase}/mfa`);
  }

  getDomains(): Observable<Domain[]> {
    return this.httpClient.get<Domain[]>(`${this.urlBase}/domain`);
  }
  getDomain(id: number): Observable<Domain> {
    return this.httpClient.get<Domain>(`${this.urlBase}/domain/${id}`);
  }

  addOrUpdateDomain(domain: Domain): Observable<Domain> {
    const body = {
      label: domain.label,
      name: domain.name
    }     
    if (domain.id) {
      return this.httpClient.post<Domain>(`${this.urlBase}/domain/${domain.id}`, body);
    } else {
      return this.httpClient.post<Domain>(`${this.urlBase}/domain`, body);
    }
  }  

  deleteDomain(id: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.urlBase}/domain/${id}`);
  }
};

export class LoginResponse {
  status: string | undefined;
  user: User | undefined;
  token: string | undefined;
};

export class User {
  id: number | undefined;
  email: string | undefined;
  profile: string | undefined;
  lastCnx: string | undefined;
  pwd: string | undefined;
  mfa: boolean | undefined;
  sapNumbers: string[] = [];
  accessRequestStatus: number | undefined = undefined;
  accessRequestReason: string | undefined = undefined;

  check(): string {
    // email
    if (this.email) {
      const regex = this.profile == 'USR' ? /^([a-z0-9._%+-]+)?@[a-z0-9.-]+\.[a-z]{2,4}$/ : /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      const ok = regex.test(this.email);
      if (!ok) {
        return 'Adresse email non valide';
      }
    } else {
      return 'Veuillez saisir une adresse email';
    }

    // Mot de passe 

    if (!this.id && (this.profile != 'USR') && (!this.pwd || this.pwd.length < 7)) {
      return 'Veuillez saisir un mot de passe (minimum 7 caractères)';
    }

    // N° SAP
    if ((this.profile == 'USR') && (this.sapNumbers.length == 0)) {
      return 'Veuillez renseigner au moins un numéro SAP';
    }

    return '';
  }
};
export class EzUser {
  id: number = 0;
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  company: string = '';
  source: string = '';
  sourceCode: string = '';
};
export class AccessRequest {
  id: number | undefined = undefined;
  user: User | undefined = undefined;
  ts: string | undefined = undefined;
  siret: string | undefined = undefined;
  company: string | undefined = undefined;
  zip: string | undefined = undefined;
  city: string | undefined = undefined;
  status: number | undefined = undefined;  

  check(): string {
    // SIRET
    if (this.siret) {
      const regex = /^[0-9]{14}?$/;
      const ok = regex.test(this.siret);
      if (!ok) {
        return 'Numéro SIRET non valide';
      }
    } else {
      return 'Veuillez saisir un numéro SIRET';
    }

    if (!this.company) {
      return 'Veuillez saisir le nom de votre société';
    }

    if (!this.zip) {
      return 'Veuillez saisir le code postal';
    }

    if (!this.city) {
      return 'Veuillez saisir la ville';
    }

    
    return '';
  }
};
export class Domain {
  id: number | undefined;
  label: string | undefined;
  name: string | undefined;

  check(): string {
    // Nom
    if (!this.label) {
      return 'Veuillez saisir un nom pour ce domaine';
    }
    // Domaine
    if (this.name) {
      const regex = /^([a-z0-9.\-_]+\.)+[a-z]{2,4}$/;
      const ok = regex.test(this.name);
      if (!ok) {
        return 'Domaine email non valide';
      }
    } else {
      return 'Veuillez saisir une domaine email';
    }

    return '';
  }
}
