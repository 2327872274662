import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InfoService extends ApiService {

  getInfo(code: string): Observable<string> {
    return this.httpClient.get<string>(`${this.urlBase}/info/${code}`);
  }

  getCompanyFromSIRET(siret: string): Observable<CompanyInfo> {
    return this.httpClient.get<CompanyInfo>(`${this.urlBase}/search/siret/${siret}`);
  }
  
};

export class CompanyInfo {
  siret: string | undefined = undefined;
  name: string | undefined = undefined;
  zip: string | undefined = undefined;
  city: string | undefined = undefined;
  error: string | undefined = undefined;
};